import { Router } from "@reach/router";
import React, { PropsWithChildren, useEffect } from "react";
import { IWithLibAnalytics, withLibAnalytics } from "../hocs/withLibAnalytics";
import { Head } from "./Head";
import { Store } from "./Store";

interface IProps {}

const LayoutDecorated = (props: PropsWithChildren<IProps & IWithLibAnalytics>) => {
    const { children, analytics } = props;

    useEffect(() => {
        analytics.page();
    }, []);

    return (
        <Store>
            <Head />
            <Router>{children}</Router>
        </Store>
    );
};

export const Layout = withLibAnalytics(LayoutDecorated);
