import React, { PropsWithChildren } from "react";
import { labels } from "../../common/consts/labels";
import { BingoCardState } from "../../common/enums/BingoCardState";
import { BingoCard } from "../../common/types/BingoCard";
import { IWithActions, withActions } from "../hocs/withActions";
// import { IWithAnalytics, withAnalytics } from "../hocs/withAnalytics";
import { IWithLibAnalytics, withLibAnalytics } from "../hocs/withLibAnalytics";

interface IProps {
    card: BingoCard;
    index: number;
}

const CardDecorated = (props: PropsWithChildren<IProps & IWithActions & IWithLibAnalytics>) => {
    const {
        index,
        card,
        actions: { setGot },
        analytics,
    } = props;

    const classNames = ["bingo-card", `state-${card.state}`];

    const onClick = () => {
        if (card.state === BingoCardState.NotGot) {
            setGot(index, BingoCardState.Got);
            analytics.track("clickGot", { category: "card", label: labels[card.labelIndex] });
        } else {
            setGot(index, BingoCardState.NotGot);
            analytics.track("clickNotGot", { category: "card", label: labels[card.labelIndex] });
        }
    };

    return (
        <div className={classNames.join(" ")} onClick={onClick}>
            <span>{labels[card.labelIndex]}</span>
        </div>
    );
};

export const Card = withLibAnalytics(withActions(CardDecorated));
