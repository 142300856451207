import { any } from "ramda";
import { createSelector } from "reselect";
import { BingoCardState } from "../../../common/enums/BingoCardState";
import { getCards } from "./getCards";

export const getIsBingo = createSelector([getCards], (cards) => {
    return any((card) => {
        return card.state === BingoCardState.Bingo;
    }, cards);
});
