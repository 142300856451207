/* eslint-disable unicorn/number-literal-case */
const base64Factory = (alphabet = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_/") => {
    // binary to string lookup table
    const b2s = alphabet.split("");

    // string to binary lookup table
    // 123 == 'z'.charCodeAt(0) + 1
    const s2b: number[] = new Array(123);
    for (let i = 0; i < alphabet.length; i++) {
        s2b[alphabet.charCodeAt(i)] = i;
    }

    // number to base64
    const stringify = (input: number): string => {
        if (input < 0) return `-${stringify(-input)}`;

        let lo = input >>> 0;
        let hi = (input / 4294967296) >>> 0;

        let right = "";
        while (hi > 0) {
            right = b2s[0x3f & lo] + right;
            lo >>>= 6;
            lo |= (0x3f & hi) << 26;
            hi >>>= 6;
        }

        let left = "";
        do {
            left = b2s[0x3f & lo] + left;
            lo >>>= 6;
        } while (lo > 0);

        return left + right;
    };

    const parse = (input: string): number => {
        let number = 0;
        const sign = input.charAt(0) === "-" ? 1 : 0;

        for (let i = sign; i < input.length; i++) {
            number = number * 64 + s2b[input.charCodeAt(i)];
        }

        return sign ? -number : number;
    };

    return { stringify, parse };
};

export const base64 = base64Factory();
