import { useLocation } from "@reach/router";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { shareCodeToCards } from "../cards/shareCodeToCards";
import { IWithActions, withActions } from "../hocs/withActions";

interface IProps {}

const InitialiseFromLocationDecorated = (props: PropsWithChildren<IProps & IWithActions>) => {
    const { actions: { setCards } } = props;
    const [shareCodeFromLocation, setShareCodeFromLocation] = useState("");
    const { search } = useLocation();
    const nextShareCodeFromLocation = search.slice(1);

    if (nextShareCodeFromLocation !== shareCodeFromLocation) {
        setShareCodeFromLocation(nextShareCodeFromLocation);
    }

    useEffect(() => {
        if (shareCodeFromLocation !== "") {
            setCards(shareCodeToCards(shareCodeFromLocation));
        }
    }, [shareCodeFromLocation]);

    return <></>;
};

export const InitialiseFromLocation = withActions(InitialiseFromLocationDecorated);
