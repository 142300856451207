import React, { PropsWithChildren } from "react";
import { IWithState, withState } from "../hocs/withState";
import { Bingo } from "./Bingo";
import { Card } from "./Card";

interface IProps {}

const BoardDecorated = (props: PropsWithChildren<IProps & IWithState>) => {
    const {
        state: { cards },
    } = props;

    return (
        <div className={"bingo-board"}>
            {cards.map((card, index) => {
                return <Card card={card} key={index} index={index} />;
            })}
            <Bingo />
        </div>
    );
};

export const Board = withState<IProps>(BoardDecorated);
