import { ActionType } from "../../../common/enums/ActionType";
import { BingoCard } from "../../../common/types/BingoCard";
import { SetCardsAction } from "../actions/SetCardsAction";

export const createSetCardsAction = (cards: BingoCard[]): SetCardsAction => {
    return {
        type: ActionType.SetCards,
        cards,
    };
};
