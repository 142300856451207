import React, { PropsWithChildren } from "react";
import { IWithState, withState } from "../hocs/withState";

interface IProps {}

const BingoDecorated = (props: PropsWithChildren<IProps & IWithState>) => {
    const { derivedState } = props;

    if (!derivedState.isBingo) {
        return <></>;
    }

    return (
        <div className={"bingo"}>
            <h1>Bingo!</h1>
        </div>
    );
};

export const Bingo = withState<IProps>(BingoDecorated);
