import React from "react";

export const Logo = () => {
    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className={"logo"} href={"https://www.likeaphoton.com"} target={"_blank"}>
            <h1>Like a Photon Creative</h1>
        </a>
    );
};
