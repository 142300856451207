import { all } from "ramda";
import { BingoCardState } from "../../common/enums/BingoCardState";
import { BingoCard } from "../../common/types/BingoCard";

export const bingoPass = (indexes: number[], cards: BingoCard[]): BingoCard[] => {
    if (
        all((index) => {
            return cards[index].state > BingoCardState.NotGot;
        }, indexes)
    ) {
        indexes.forEach((index) => {
            cards[index] = { ...cards[index], state: BingoCardState.Bingo };
        });
    }

    return [...cards];
};
