import { BingoCardState } from "../../common/enums/BingoCardState";
import { BingoCard } from "../../common/types/BingoCard";
import { ShareCode } from "../../common/types/ShareCode";
import { base64 } from "../utils/base64";
const { parse } = base64;

export const shareCodeToCards = (shareCode: ShareCode): BingoCard[] => {
    const cards: BingoCard[] = [];

    for (let i = 0; i < 50; i += 2) {
        const labelIndex = parse(shareCode[i]);
        const state: BingoCardState = parse(shareCode[i + 1]);
        cards.push({ labelIndex, state });
    }

    return cards;
};
