import { base64 } from "../utils/base64";
import { ShareCode } from "../../common/types/ShareCode";
import { State } from "../../common/types/State";

const { stringify } = base64;

export const stateToShareCode = (state: State): ShareCode => {
    return state.cards.reduce((code, nextCard) => {
        return code + stringify(nextCard.labelIndex) + stringify(nextCard.state);
    }, "");
};
