import { ComponentType } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { createSetCardsAction } from "../redux/actionCreators/createSetCardsAction";
import { createSetGotAction } from "../redux/actionCreators/createSetGotAction";

export interface IWithActions {
    actions: {
        setGot: typeof createSetGotAction;
        setCards: typeof createSetCardsAction,
    };
}

const wrapDispatch = <T extends Action, U extends unknown[]>(dispatch: Dispatch<T>, fn: (...args: U) => T) => {
    return (...args: U) => {
        return dispatch(fn(...args));
    };
};

export const withActions = <TProps>(Component: ComponentType<TProps & IWithActions>): ComponentType<TProps> => {
    const mapDispatchToProps = (dispatch: Dispatch): IWithActions => {
        return {
            actions: {
                setGot: wrapDispatch(dispatch, createSetGotAction),
                setCards: wrapDispatch(dispatch, createSetCardsAction),
            },
        };
    };

    return connect(null, mapDispatchToProps)(Component as ComponentType<unknown>);
};
