import React, { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { store } from "../redux/store";

interface IProps {}

export const Store = (props: PropsWithChildren<IProps>) => {
    const { children } = props;

    return <Provider store={store}>{children}</Provider>;
};
