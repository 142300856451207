import React from "react";
import { Helmet } from "react-helmet";

export const Head = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>LAPC Conference Call Bingo</title>
            <link href="https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap" rel="stylesheet" />
        </Helmet>
    );
};
