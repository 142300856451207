import React, { PropsWithChildren } from "react";
import { stateToShareCode } from "../cards/stateToShareCode";
import { IWithState, withState } from "../hocs/withState";
import { useLocation } from "@reach/router";

interface IProps {}

const StateDecorated = (props: PropsWithChildren<IProps & IWithState>) => {
    const { state } = props;

    const shareCode = stateToShareCode(state);
    const location = useLocation();
    const url = `${location.protocol}//${location.host}?${shareCode}`;

    if (location.protocol) {
        return (
            <div className={"state"}>
                Share: <a href={url}>{url}</a>
            </div>
        );
    }

    return <></>;
};

export const State = withState<IProps>(StateDecorated);
