import React, { PropsWithChildren } from "react";
import { Board } from "./Board";
import { Footer } from "./Footer";
import { InitialiseFromLocation } from "./InitialiseFromLocation";
import { Logo } from "./Logo";
import { RegenerateButton } from "./RegenerateButton";
import { State } from "./State";

interface IProps {
    path: string;
}

export const Main = (props: PropsWithChildren<IProps>) => {
    return (
        <>
            <Logo />
            <Board />
            <RegenerateButton />
            <State />
            <Footer />
            <InitialiseFromLocation />
        </>
    );
};
