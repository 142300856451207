import { ComponentType } from "react";
import { connect } from "react-redux";
import { DerivedState } from "../../common/types/DerivedState";
import { State } from "../../common/types/State";
import { getIsBingo } from "../redux/selectors/getIsBingo";

export interface IWithState {
    state: State;
    derivedState: DerivedState;
}

const mapStateToProps = (state: State): IWithState => {
    return {
        state,
        derivedState: {
            isBingo: getIsBingo(state),
        },
    };
};

export const withState = <TProps>(Component: ComponentType<TProps & IWithState>): ComponentType<TProps> => {
    return connect(mapStateToProps, null)(Component as ComponentType<unknown>);
};
