import { range } from "ramda";
import { BingoCardState } from "../../common/enums/BingoCardState";
import { BingoCard } from "../../common/types/BingoCard";
import { State } from "../../common/types/State";
import { bingoPass } from "./bingoPass";

export const bingoedCards = (state: State): BingoCard[] => {
    let { cards } = state;

    // Unbingo-ify all cards
    cards = cards.map((card) => {
        return { ...card, state: card.state === BingoCardState.Bingo ? BingoCardState.Got : card.state };
    });

    // Check horizontal
    range(0, 5).forEach((row) => {
        const baseIndex = row * 5;
        const indexes = range(baseIndex, baseIndex + 5);
        cards = bingoPass(indexes, cards);
    });

    // Check vertical
    range(0, 5).forEach((column) => {
        const indexes = range(0, 5).map((row) => {
            return column + row * 5;
        });
        cards = bingoPass(indexes, cards);
    });

    // Check diagonal TL to BR
    const indexTl = range(0, 5).map((row) => {
        const rowIndex = row * 5;
        return rowIndex + row;
    });
    const indexBr = range(0, 5).map((row) => {
        const rowIndex = row * 5;
        return rowIndex + (4 - row);
    });
    cards = bingoPass(indexTl, cards);
    cards = bingoPass(indexBr, cards);

    return [...cards];
};
