import googleAnalytics from "@analytics/google-analytics";
import Analytics, { IAnalytics } from "analytics";
import React, { ComponentType } from "react";

const analytics = Analytics({
    app: "conference-call-bingo",
    plugins: [
        googleAnalytics({
            trackingId: `${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`,
        }),
    ],
});

export interface IWithLibAnalytics {
    analytics: IAnalytics;
}

export const withLibAnalytics = <TProps,>(Component: ComponentType<TProps & IWithLibAnalytics>): ComponentType<TProps> => {
    const WithAnalytics = (props: TProps) => {
        return <Component {...props} analytics={analytics} />;
    };

    return WithAnalytics;
};
