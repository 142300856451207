import shuffle from "lodash.shuffle";
import { range } from "ramda";
import { labels } from "../../common/consts/labels";
import { BingoCardState } from "../../common/enums/BingoCardState";
import { BingoCard } from "../../common/types/BingoCard";

export const freshCards = (): BingoCard[] => {
    const indexes = shuffle(range(0, labels.length));

    // Ensure the first label stays in position 12
    indexes.splice(indexes.indexOf(0), 1);
    indexes.splice(12, 0, 0);

    return indexes.slice(0, 25).map((index) => {
        return {
            labelIndex: index,
            state: index === 0 ? BingoCardState.Got : BingoCardState.NotGot,
        };
    });
};
