import React, { PropsWithChildren } from "react";
import { freshCards } from "../cards/freshCards";
import { IWithActions, withActions } from "../hocs/withActions";

interface IProps {}

const RegenerateButtonDecorated = (props: PropsWithChildren<IProps & IWithActions>) => {
    const {
        actions: { setCards },
    } = props;

    const onClick = () => {
        setCards(freshCards());
    };

    return <button onClick={onClick}>Generate new board</button>;
};

export const RegenerateButton = withActions(RegenerateButtonDecorated);
