import { ActionType } from "../../common/enums/ActionType";
import { BingoCard } from "../../common/types/BingoCard";
import { State } from "../../common/types/State";
import { bingoedCards } from "../cards/bingoedCards";
import { freshCards } from "../cards/freshCards";
import { Action } from "./Action";
import { SetCardsAction } from "./actions/SetCardsAction";
import { SetGotAction } from "./actions/SetGotAction";

const isSetGotAction = (action: Action): action is SetGotAction => {
    return action.type === ActionType.SetGot;
};

const isSetCardsAction = (action: Action): action is SetCardsAction => {
    return action.type === ActionType.SetCards;
};

export const reducer = (previousState: State = { cards: freshCards() }, action: Action): State => {
    const { cards } = previousState;
    let nextState: State = { ...previousState };

    if (isSetGotAction(action)) {
        const { state, index } = action;
        nextState = {
            ...nextState,
            cards: cards.reduce((newCards: BingoCard[], nextCard, nextIndex) => {
                if (nextIndex === index) {
                    newCards.push({ ...nextCard, state });
                } else {
                    newCards.push(nextCard);
                }
                return newCards;
            }, []),
        };
    } else if (isSetCardsAction(action)) {
        const { cards: newCards } = action;
        nextState = { ...nextState, cards: [...newCards] };
    }

    // Check for bingo
    nextState = { ...nextState, cards: bingoedCards(nextState) };

    return nextState;
};
