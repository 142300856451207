import { ActionType } from "../../../common/enums/ActionType";
import { BingoCardState } from "../../../common/enums/BingoCardState";
import { SetGotAction } from "../actions/SetGotAction";

export const createSetGotAction = (index: number, state: BingoCardState): SetGotAction => {
    return {
        type: ActionType.SetGot,
        index,
        state,
    };
};
